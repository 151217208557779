import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import axios from '../../utils/axios';
import { useSnackbar } from 'notistack';
import useSettings from '../../hooks/useSettings';
//import Bugsnag from '@bugsnag/js'

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  centerContainer: {
    minHeight: '100vh'
  }
}));

function AutoLogin({ history }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const { saveSettings } = useSettings();
    const [showProgress, setShowProgress] = useState(false);

    const parsed = queryString.parse(window.location.search);
    if (parsed.pin !== undefined && !showProgress)
    {
        setShowProgress(true);

        const pin = parsed.pin;
        const password = parsed.password;

        axios.post(process.env.REACT_APP_API_BASE_URL + '/terminal', {
          pin: pin,
          password: password
        }, { headers: { 'Authorization': 'Bearer ' + pin}})
        .then((response) => {
          saveSettings({
            terminalId: response.data.terminal_id,
            pin: pin,
            branch: response.data.branch,
            branchId: response.data.branch_id
          });

          window.location.href = '/';
        }).catch((error) => {
          //Bugsnag.notify(error);
          
          enqueueSnackbar(error.response.data, {
            variant: 'error'
          });
        }); 
    }
    
    return (
      <Container maxWidth="sm" className={classes.centerContainer}>
        <Grid 
        container 
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.centerContainer}>
            <Backdrop className={classes.backdrop} open={showProgress}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
      </Container>
    );
  }
  
  export default AutoLogin;
  