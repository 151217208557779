import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PinField from "react-pin-field"
import { NumericKeyboard, Keys } from 'numeric-keyboard/dist/numeric_keyboard.react.js'
import { isMobile, isTablet } from 'react-device-detect';


const NumLayout = [
  [
    {
      key: Keys.SEVEN
    },
    {
      key: Keys.EIGHT
    },
    {
      key: Keys.NINE
    },
  ],
  [
    {
      key: Keys.FOUR
    },
    {
      key: Keys.FIVE
    },
    {
      key: Keys.SIX
    },
  ],
  [
    {
      key: Keys.ONE
    },
    {
      key: Keys.TWO
    },
    {
      key: Keys.THREE
    },
  ],
  [
    {
      key: Keys.ZERO
    },
    {
      key: Keys.BLANK
    },
    {
      key: Keys.DEL
    },
  ],
]

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6, 0, 3),
  },
  rootMobile: {
    margin: theme.spacing(3, 0, 1),
    width: "100%",
    '@media (orientation: landscape) and (max-height: 801px)': {
      margin: theme.spacing(1, 0, 1),
    }
  },
  pinField: {
    width: 120,
    height: 150,
    fontSize: "80px",
    border: "3px solid #999",
    borderRadius: "10px",
    margin: 10,
    outline: "none",
    textAlign: "center",
    
  },
  pinFieldMobile: {
    width: "70px",
    height: "90px",
    background: "inherit",
    fontSize: "90px",
    border: 0,
    margin: 0,
    outline: "none",
    textAlign: "center",
    '@media (orientation: landscape) and (max-height: 801px)': {
      height: '50px',
      fontSize: '50px',
    }
  },
  keyboard: {
    marginTop: '20px',
    '& table': {
      borderSpacing: '40px 20px',
      background: 'unset',
      width: "auto",
      '@media (orientation: landscape) and (max-height: 801px)': {
        borderSpacing: '40px 10px',
        margin: "auto",
      }
    },
    '& td': {
      width: '130px',
      height: '130px',
      border: '1px solid #666',
      borderRadius: '100%',
      fontSize: '58px',
      '@media (orientation: landscape) and (max-height: 801px)': {
        width: '90px',
        height: '90px',
        fontSize: '40px',
      }
    },
    '& tr:nth-child(4) td:nth-child(2)': {
      visibility: 'hidden'
    },
    '& tr:nth-child(4) td:nth-child(3):before': {
      position: 'relative',
      top: '5px'
    }
  }
}));

export default function ProTip({ onChange, onKeyUp, disabled, ...rest }) {
  /* const [blocked, setBlocked] = useState(false) */
  const [current, setCurrent] = useState(0)
  const [field0, setField0] = useState('')
  const [field1, setField1] = useState('')
  const [field2, setField2] = useState('')
  const [field3, setField3] = useState('')
  const [blocked, setBlocked] = useState(false)

  const classes = useStyles();

  /*const inputRef = useRef(null);

  if (inputRef && inputRef.current != null)
  {
    inputRef.current.forEach(input => (input.type = 'password'));
  }
  
  useEffect(() => {
    if (!blocked) {
      inputRef.current[0].focus()
    }
  }, [blocked])

  const handleChange = (e, code, mobile) => {
      if (code.length === 4)
      {
        setBlocked(true);
        onChange(code);
  
        setTimeout(function()
        {
          if (inputRef !== null && inputRef.current !== null)
          {
            inputRef.current.forEach(input => (input.value = ""));
  
            if (!mobile)
            {
              inputRef.current[0].focus();
            }
            setBlocked(false);
          }
  
        }, 300);
  
      }
      else
      {
        if (onKeyUp)
        {
          onKeyUp(code);
        }
        
    }
    
  };

  const press = (key) => {
    if(key === "del")
    {
      //handle del
      for(var i=inputRef.current.length-1; i>=0; i--)
      {
        if(inputRef.current[i].value !== '')
        {
          inputRef.current[i].value = '';
          inputRef.current[i].blur();
          break;
        }
      }      
    }
    else
    {
      //handle numeric
      for(i=0; i<inputRef.current.length; i++)
      {
        if(inputRef.current[i].value === '')
        {
          inputRef.current[i].value = key;
          inputRef.current[i].blur();
          
          if (i === 3)
          {
            handleChange(null, inputRef.current.map(function(x){return x.value;}).join(''), true);
          }
          break;
        }
      }    
    }    

  }; */

  const handleChange = (nr, value) => {
    if (value.length == 2) value = value.slice(1)

    if (value === 'del') {
      handleKeypadDel()
      return
    }

    if (!(value in ['0','1','2','3','4','5','6','7','8','9'])) {
      if (nr == 0) setField0('')
      if (nr == 1) setField1('')
      if (nr == 2) setField2('')
      if (nr == 3) setField3('')
      return
    }
    
    if (typeof onKeyUp !== 'undefined') {
      onKeyUp(field0 + field1 + field2 + field3)
    }

    if (nr == 0) {
      setField0(value)
      if (!isMobile && !isTablet) {
        document.getElementById('p-1').focus()
      }
      setCurrent(1)
    }
    else if (nr == 1) {
      setField1(value)
      if (!isMobile && !isTablet) {
        document.getElementById('p-2').focus()
      }
      setCurrent(2)
    }
    else if (nr == 2) {
      setField2(value)
      if (!isMobile && !isTablet) {
        document.getElementById('p-3').focus()
      }
      setCurrent(3)
    }
    else if (nr == 3) {
      setField3(value)
      setBlocked(true)

      // Send code
      onChange(field0 + field1 + field2 + value)

      // Set delay to reset
      setTimeout(function() {
        setField0('')
        setField1('')
        setField2('')
        setField3('')
        setBlocked(false)
        if (!(isMobile || isTablet)) {
          document.getElementById('p-0').focus()
        }
        setCurrent(0)
      }, 300)
    }
  }

  const handleKeypadDel = () => {
    if (current == 1) {
      setField0('')
      setCurrent(0)
    }
    else if (current == 2) {
      setField1('')
      setCurrent(1)
    }
    else if (current == 3) {
      setField2('')
      setCurrent(2)
    }
  }

  const handleReturn = (e) => {
    if (e.keyCode == 8 || e.keyCode == 46) {
      e.preventDefault()
      if (current == 1) {
        setField0('')
        setCurrent(0)
        if (!isMobile && !isTablet) {
          document.getElementById('p-0').focus()
        }
      }
      else if (current == 2) {
        setField1('')
        setCurrent(1)
        if (!isMobile && !isTablet) {
          document.getElementById('p-1').focus()
        }
      }
      else if (current == 3) {
        setField2('')
        setCurrent(2)
        if (!isMobile && !isTablet) {
          document.getElementById('p-2').focus()
        }
      }
    }
  }

  return (
    <div className={((isMobile || isTablet) ? classes.rootMobile : classes.root)}>      

      <input 
        id='p-0' 
        value={field0.length > 0 ? '•' : ''} 
        onChange={(e) => handleChange(0, e.target.value)} 
        onKeyUp={handleReturn}
        autoFocus={!(isMobile || isTablet)}
        className={((isMobile || isTablet) ? classes.pinFieldMobile : classes.pinField)}
        type='text'
        disabled={blocked ? 'disabled' : ''} 
      />
      <input 
        id='p-1' 
        value={field1.length > 0 ? '•' : ''} 
        onChange={(e) => handleChange(1, e.target.value)}  
        onKeyUp={handleReturn}
        className={((isMobile || isTablet) ? classes.pinFieldMobile : classes.pinField)}
        type='text' 
        disabled={blocked ? 'disabled' : ''} 
      />
      <input 
        id='p-2' 
        value={field2.length > 0 ? '•' : ''} 
        onChange={(e) => handleChange(2, e.target.value)}  
        onKeyUp={handleReturn}
        className={((isMobile || isTablet) ? classes.pinFieldMobile : classes.pinField)}
        type='text' 
        disabled={blocked ? 'disabled' : ''} 
      />
      <input 
        id='p-3' 
        value={field3.length > 0 ? '•' : ''} 
        onChange={(e) => handleChange(3, e.target.value)}  
        onKeyUp={handleReturn}
        className={((isMobile || isTablet) ? classes.pinFieldMobile : classes.pinField)}
        type='text' 
        disabled={blocked ? 'disabled' : ''} 
      />
      {/* <PinField
   
        className={((isMobile || isTablet) ? classes.pinFieldMobile : classes.pinField)}
        onChange={(code) => handleChange(this, code, false)}
        format={k => k.toUpperCase()}
        length={4}
        autoFocus={!(isMobile || isTablet)}
        disabled={blocked ? 'disabled' : ''}
      /> */}

      {(isMobile || isTablet) &&
          <div className={classes.keyboard}>      
          <NumericKeyboard layout={NumLayout} onPress={ (value) => { 
            if (!blocked) {
              handleChange(current, value)} 
            }
          } />
        </div>
      }
    </div>
    
  );
}
