import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import VerifyCode from '../../components/VerifyCode';
import ConsumerList from '../../components/ConsumerList';
import useSettings from '../../hooks/useSettings';
import Smiley from '../../components/Smiley';
import { isMobile, isTablet } from 'react-device-detect';

import ting from "../../audio/ting.wav";
import error from "../../audio/error2.mp3";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useIdleTimer } from 'react-idle-timer'
//import Bugsnag from '@bugsnag/js'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f58220'
  },
  logo: { 
    flexGrow: 1,
    textAlign: 'left'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    textAlign: "center",
    zIndex: 20,
    '@media (orientation: landscape) and (max-height: 801px)': {
      margin: 0,
      padding: 0
    }
  },
  containerTopBox: {
    textAlign: "center",
    height: "240px",
    '@media (orientation: landscape) and (max-height: 801px)': {
      paddingTop: '90px',
      height: "230px",
      '& div': {
        marginBottom: '8px'
      }
    }
  },
  centerContainer: {
    minHeight: '100vh',
  },
  logout: {
    marginLeft: 2
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  footer: {
    position: 'fixed',
    bottom: '30px',
    right: '10px',
    left: '10px',
    [theme.breakpoints.down('lg')]: {
      bottom: '10px'
    }
  },
  footerLeft: {
    textAlign: 'left'
  },
  footerRight: {
    textAlign: 'right'
  },
  success: {
    color: 'green'
  },
  error: {
    color: 'red'
  }
}));

function AllocateView({ }) {
    const classes = useStyles();

    const { settings, saveSettings } = useSettings();

    const [message, setMessage] = useState(null);

    const [smiley, setSmiley] = useState(null);
    
    const [socketId, setSocketId] = useState('');
    const [fulfilments, setFulfilments] = useState([]);
    const [reload, setReload] = useState(0);
    const [fulfilmentsToAdd, setFulfilmentsToAdd] = useState([]);
    const [fulfilmentsToDelete, setFulfilmentsToDelete] = useState([]);
    const [fulfilmentsToSend, setFulfilmentsToSend] = useState([]);

    const getFulfilments = () => {
      fetch(process.env.REACT_APP_API_BASE_URL + '/terminalv2/' + settings.terminalId, { headers: { 'Authorization': 'Bearer ' + settings.pin}})
        .then(res => {
          if (res.status == 404) {
            localStorage.removeItem('terminal')
            window.location.href = '/'
          }
          return res.json()
        })
        .then(res => {
          if(res === null || (!typeof(res) == 'object')) {
            //Bugsnag.notify(res);
            console.log("error", res)
          } else {
            setFulfilments(res)
          } 
        })
        .catch((error) => {
          //Bugsnag.notify(error);
          console.log("error", error)
        });
    }

    const sendAllocations = () => {
      if (fulfilmentsToSend.length > 0) {
        let batch = fulfilmentsToSend.slice(0,10)
        fetch(
          process.env.REACT_APP_API_BASE_URL + '/socket/allocate', 
          {
            method: 'POST',
            headers: { 'Content-Type':'application/json', 'X-Socket-ID': socketId },
            body: JSON.stringify({ allocations: batch })
          }
        )
        .then((resp) => {
          if (resp.status == 200)
          {
            // Fulfilments sent, update queue
            setFulfilmentsToSend(fulfilmentsToSend.slice(10))
          }
          else
          {
            //Bugsnag.notify(resp);
          }
        })
        .catch((error) => {
          // Fetch failed
        })
      }
    }

    const updateFulfilment = (pin) => {
      let copy = fulfilments.slice();
      let index = copy.findIndex((f) => f.code == pin);
      if (index > -1) {
        copy[index].allocated_at = new Date();
        copy[index].branch_terminal_id = settings.terminalId;
        setFulfilments(copy);
        setFulfilmentsToSend([...fulfilmentsToSend, copy[index]])
      }
    }

    const closeConnection = () => {
      window.Echo.disconnect()
    }

    const openConnection = () => {
      // Get all fulfilments
      getFulfilments()

      // Create Echo instance
      window.Pusher = Pusher;
 
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_PUSHER_KEY,
        cluster: 'eu',
        forceTLS: true
      });

      // Set up channel listeners
      window.Echo.channel('allocations.' + settings.branchId)
      .listen('.newAllocations', (data) => {
        if (data.action == 'delete') {
          setFulfilmentsToDelete(data.allocations)
        } else {
          setFulfilmentsToAdd(data.allocations)
        }
      })

      window.Echo.channel('reload')
      .listen('.reloadAllocations', () => {
        getFulfilments()
      })

      setSocketId(window.Echo.socketId())
    }

    
    useEffect(() => {
      // Get new settings if settings are outdated
      if (settings.branchId == undefined) {
        fetch(
          process.env.REACT_APP_API_BASE_URL + '/terminal', 
          {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + settings.pin},
            body: []
          }
        )
        .then((res) => {
          if (res.status == 404) {
            localStorage.removeItem('terminal')
            window.location.href = '/'
          }
          return res.json()
        })
        .then((response) => {
          saveSettings({
            terminalId: response.terminal_id,
            pin: settings.pin,
            branch: response.branch,
            branchId: response.branch_id
          })
        })
        .catch((error) => {
          // Something went wrong, redirect to login
          localStorage.removeItem('terminal')
          window.location.href = '/'
        });
      }
      
    }, [])

    useEffect(() => {
      if (settings.branchId != null) {
        // Open socket connection on initial load
        openConnection()
      }
    }, [settings])

    useEffect(() => {
      // Send allocations if there are 10 unsent allocations
      if (fulfilmentsToSend.length  >= 1) { // 10 - Fix after testing
        sendAllocations()
      }
    }, [fulfilmentsToSend])

    useEffect(() => {
      // If we get new fulfilment updates from websocket, update fulfilments accordingly
      if (fulfilmentsToAdd.length) {
        let copy = fulfilments.slice();
        for (let i = 0; i < fulfilmentsToAdd.length; i++) {
          let index = copy.findIndex(f => f.code == fulfilmentsToAdd[i].code);
          if (index != -1) {
            // Fulfilment exists, update
            copy[index].allocated_at = fulfilmentsToAdd[i].allocated_at
            copy[index].branch_terminal_id = fulfilmentsToAdd[i].branch_terminal_id
          } else {
            // New fulfilment added, add to list
            copy.push(fulfilmentsToAdd[i])
          }
        }
        setFulfilments(copy);
        setReload(reload + 1)
        setFulfilmentsToAdd([])
      }
    }, [fulfilmentsToAdd])

    useEffect(() => {
      // Delete fulfilments in delete queue
      if (fulfilmentsToDelete.length) {
        let copy = fulfilments.slice();
        for (let i = 0; i < fulfilmentsToDelete.length; i++) {
          let index = copy.filter(f => f.code != fulfilmentsToDelete[i].code);
          if (index != -1) {
            // Fulfilment exists, remove from array
            copy.splice(index, 1)
          }
        }
        setFulfilments(copy);
        setReload(reload + 1)
        setFulfilmentsToDelete([])
      }
    }, [fulfilmentsToDelete])

    // Send allocations after 10 seconds of inactivity
    useIdleTimer({
      timeout: 1000*10, // 10 sec min
      onIdle: sendAllocations
    })

    // Close socket connection after 30 minutes of inactivity
    useIdleTimer({
      timeout: 1000*60*2, // 1000*60*30 - Fix after testing
      onIdle: closeConnection,
      onActive: openConnection
    })

    const tingAudio = new Audio(ting);
    const errorAudio = new Audio(error);

    const playSound = audioFile => {
      try {
        audioFile.play();
      } catch (err) {
        console.log("Failed to play audio:", err);
      }
    }
    
    const handleKeyUp = (pin) => {
      if (pin.length !== 4)
      {
        setSmiley(null);
        setMessage(null);
      }
    }

    const handleChange = (pin) => {
      
      if (pin.length === 4)
      {
        let found = false;
        let allocated = false;

        for (var i=0; i < fulfilments.length; i++)
        {
          if (fulfilments[i].code === pin)
          {
            found = true;
            if (fulfilments[i].allocated_at != null)
            {
              allocated = true;
            }

            break;
          }
        }

        if (found)
        {
          if (allocated)
          {
            playSound(errorAudio);

            setSmiley("/static/fail.svg");
            setMessage('Nemandi hefur þegar fengið máltíð í dag');
          }
          else
          {
            playSound(tingAudio);

            setSmiley("/static/ok.svg");
            setMessage('Gjörðu svo vel ' + JSON.parse(fulfilments[i].details).consumer + ' ' + JSON.parse(fulfilments[i].details).group + '!');

            updateFulfilment(pin);
          }
        }
        else
        {
          playSound(errorAudio);
          
          setSmiley("/static/fail.svg");
          setMessage('Áskrift fannst ekki með þessu skólanúmeri');
        }

        setTimeout(function()
        {
          //setMessage(null);
        }, 4000);
      }
    };

    return (
      <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logo}>
          <ConsumerList allocations={fulfilments} closeConnection={closeConnection} />
          </Box>
          <Typography component="p">
              {settings.branch} 

              {fulfilments.product_type > 0 &&
              <>
              {fulfilments.product_type === 1 && ' - Hádegi'}
              {fulfilments.product_type === 2 && ' - Síðdegisnesti'}
              {fulfilments.product_type === 3 && ' - Ávaxtanesti'}
              {fulfilments.product_type === 4 && ' - Hafragrautur'}
              </>
              }
              - Hádegi
            </Typography>
        </Toolbar>
      </AppBar>

      {(isMobile || isTablet) &&
      <Container maxWidth={false} disableGutters={true} className={classes.centerContainer}>
      <Grid 
      container 
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.centerContainer}>
        <Box pt={12} className={classes.containerTopBox}>
        {smiley !== null && smiley === "/static/ok.svg" &&
            <Smiley src="/static/ok.svg" />
          }

          {smiley !== null && smiley === "/static/fail.svg" &&
            <Smiley src="/static/fail.svg" />
          }
            
            <Fade in={(message !== null)}>
              <Typography variant="h6">
              {message}
              </Typography>
            </Fade>

        </Box>

        <Box my={4} pt={4} pb={4} px={8} className={classes.container}>

        {fulfilments != null &&
          <VerifyCode onChange={(pin) => handleChange(pin)} onKeyUp={(pin) => handleKeyUp(pin)} />
        }

        </Box>

      </Grid>
    </Container>
      }

      {(!isMobile && !isTablet) &&
      <Container maxWidth="md" className={classes.centerContainer}>
        <Grid 
        container 
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.centerContainer}>

        <Paper elevation={0}>
          <Box my={4} pt={4} pb={4} px={15} className={classes.container}>
          {smiley !== null && smiley === "/static/ok.svg" &&
              <Smiley src="/static/ok.svg" />
            }

            {smiley !== null && smiley === "/static/fail.svg" &&
              <Smiley src="/static/fail.svg" />
            }
              
              <Fade in={(message !== null)}>
                <Typography variant="h6">
                {message}
                </Typography>
              </Fade>
              {fulfilments != null &&
              <VerifyCode onChange={(pin) => handleChange(pin)} onKeyUp={(pin) => handleKeyUp(pin)} />
              }

          </Box>
        </Paper>
        </Grid>
      </Container>
      }

      <Container maxWidth={false} className={classes.footer}>
        <Grid container>
          <Grid item xs={8} lg={6} className={classes.footerLeft}>
            <Typography>
              {(isMobile || isTablet) ?
                (fulfilments && Array.isArray(fulfilments)) &&
                <>
                  Úthlutað: {fulfilments.filter(x => x.allocated_at).length}
                  {(fulfilments && Array.isArray(fulfilments)) && 
                  <>
                    &nbsp;af {fulfilments.length}
                  </>
                  }
                </>
              :
                (fulfilments && Array.isArray(fulfilments)) && 
                <>
                  Fjöldi áskrifta í dag: {fulfilments.length}

                  {(fulfilments && Array.isArray(fulfilments)) &&
                  <>
                    &nbsp;(Úthlutað: {fulfilments.filter(x => x.allocated_at).length})
                  </>
                  }
                </>
              }
              </Typography>
          </Grid>
          <Grid item xs={4} lg={6}  className={classes.footerRight}>
            {/* <Fade in={subscriptions.pending}>
              <Typography>Sæki áskriftir...</Typography>
            </Fade> */}

          </Grid>
        </Grid>
      </Container>
      </div>
    );
  }

  AllocateView.propTypes = {
    className: PropTypes.string,
    allocations: PropTypes.object,
  };
  
  export default AllocateView;
  