import React, {
    createContext,
    useState
  } from 'react';
  import PropTypes from 'prop-types';
  import _ from 'lodash';
  import { storeSettings } from '../utils/settings';
  
  const SettingsContext = createContext();
  
  const defaultSettings = {
    terminalId: 0,
    pin: ''
  };
  
  export function SettingsProvider({ settings, children }) {
    const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);
  
    const handleSaveSettings = (updatedSettings = {}) => {
      const mergedSettings = _.merge({}, currentSettings, updatedSettings);
  
      setCurrentSettings(mergedSettings);
      storeSettings(mergedSettings);
    };
  
    return (
      <SettingsContext.Provider
        value={{
          settings: currentSettings,
          saveSettings: handleSaveSettings
        }}
      >
        {children}
      </SettingsContext.Provider>
    );
  }
  
  SettingsProvider.propTypes = {
    children: PropTypes.node.isRequired,
    settings: PropTypes.object
  };
  
  export const SettingsConsumer = SettingsContext.Consumer;
  
  export default SettingsContext;
  