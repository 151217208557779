import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import VerifyCode from '../../components/VerifyCode';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import axios from '../../utils/axios';
import { useSnackbar } from 'notistack';
import useSettings from '../../hooks/useSettings';
import { isMobile, isTablet } from 'react-device-detect';
import { Button, Input, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {},
  logo: {
    width: 'auto',
    height: '5rem',
  },
  textField: {
    fontSize: '1.15rem !important',
    width: '100%',
    backgroundColor: '#fff',
    borderColor: '#fff',
    borderRadius: '0.25rem',
  },
  input: {
    fontSize: '1.15rem',
    color: '#222',
    '&.MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: "#F58221",
      }
    }
  },
  button: {
    backgroundColor: '#F58221',
    color: '#fff',
    width: '100%',
    fontSize: '1rem',
    fontWeight: 700,
    borderRadius: '0.25rem',
    '&:hover': {
      backgroundColor: '#F58221',
      opacity: 0.8,
    },
  },
  customLabel: {
    fontSize: '1.15rem',
    '&.Mui-focused': {
      color: '#F58221',
    },
  },
  centerContainer: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
}));

function AuthView({ history }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const { saveSettings } = useSettings();
    const [showProgress, setShowProgress] = useState(false);

    const [pin, setPin] = useState(null);
    const [password, setPassword] = useState(null);

    const handleSubmit = () => {
      if (pin.length === 4 && password.length > 0)
      {
        setShowProgress(true);

        axios.post(process.env.REACT_APP_API_BASE_URL + '/terminal', {
          pin: pin,
          password: password
        }, { headers: { 'Authorization': 'Bearer ' + pin}})
        .then((response) => {
          console.log(pin);

          saveSettings({
            terminalId: response.data.terminal_id,
            pin: pin,
            password: password,
            branch: response.data.branch,
            branchId: response.data.branch_id
          });
        }).catch((error) => {
          setShowProgress(false);

          enqueueSnackbar(error.response.data, {
            variant: 'error'
          });
        });
      }
    };

    return (
      <>

          <Container maxWidth="lg" className={classes.centerContainer}>
            <Grid 
              container 
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={classes.centerContainer}>
              <Box width={(isMobile || isTablet ? '100%' : '35%')}>
                  <Box width={'100%'} textAlign={'center'} mb={4}>
                    <img src='/static/logo.svg' alt='Skólamatur' className={classes.logo} />
                  </Box>
                  
                  <Typography fontWeight={700} textAlign={'center'} fontSize={'1.5rem'} mb={4} mt={2}>
                    Innskráning
                  </Typography>

                  <Box>
                    <TextField
                      required
                      name="pin"
                      id="pin"
                      onChange={(event) => setPin(event.target.value)}
                      label="Skólanúmer"
                      type='code'
                      className={classes.textField}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.customLabel,
                        },
                      }}
                    />
                  </Box>
                  
                  <Box my={2}>
                    <TextField
                      required
                      name="password"
                      id="password"
                      onChange={(event) => setPassword(event.target.value)}
                      label="Lykilorð"
                      type="password"
                      className={classes.textField}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.customLabel,
                        },
                      }}
                    />
                  </Box>

                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => handleSubmit()}
                  >
                    Skrá inn
                  </Button>
              </Box>
            </Grid>
          </Container>
      </>
    );
  }
  
  export default AuthView;
  