import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { SettingsProvider } from './context/SettingsContext';
import { restoreSettings } from './utils/settings';
/*import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: '7faa0b2c33cb36cc62c8051e00307af7',
  plugins: [new BugsnagPluginReact()]
})*/

const settings = restoreSettings();

/*const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)*/

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <SettingsProvider settings={settings}>
        {/*<ErrorBoundary>*/}
          <App />
        {/*</ErrorBoundary>*/}
      </SettingsProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.querySelector('#root'),
);
