import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6, 0, 3),
  },
  smileyImg: {
    height: "140px",
    [theme.breakpoints.down('lg')]: {
      height: "80px",
    }
  }
}));

function Smiley({ src }, props) {
  const classes = useStyles();

  return (
    <Box mb={3}>
        <img
        className={classes.smileyImg}
        alt="Skólamatur"
        src={src}
        mb={0}
        {...props}
        />
    </Box>

  );
}

export default Smiley;
