/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Button
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import useSettings from '../hooks/useSettings';

import Logo from './Logo';


function sortByKey(array, key, direction)
{
    if (key == 'name') {
        array.sort((a,b) => {
            let x = JSON.parse(a.details).consumer
            let y = JSON.parse(b.details).consumer

            x = (""+x).toLowerCase(); 

            x = x.replace(/[á]/g,"az");
            x = x.replace(/[é]/g,"ez");
            x = x.replace(/[ð]/g,"dz");
            x = x.replace(/[þ]/g,"tz");
            x = x.replace(/[ó]/g,"oz");
            x = x.replace(/[í]/g,"iz");

            x = x.replace(/1\./g,"01.");
            x = x.replace(/2\./g,"02.");
            x = x.replace(/3\./g,"03.");
            x = x.replace(/4\./g,"04.");
            x = x.replace(/5\./g,"05.");
            x = x.replace(/6\./g,"06.");
            x = x.replace(/7\./g,"07.");
            x = x.replace(/8\./g,"08.");
            x = x.replace(/9\./g,"09.");


            y = (""+y).toLowerCase();

            y = y.replace(/[á]/g,"az");
            y = y.replace(/[é]/g,"ez");
            y = y.replace(/[ð]/g,"dz");
            y = y.replace(/[þ]/g,"tz");
            y = y.replace(/[ó]/g,"oz");
            y = y.replace(/[í]/g,"iz");

            y = y.replace(/1\./g,"01.");
            y = y.replace(/2\./g,"02.");
            y = y.replace(/3\./g,"03.");
            y = y.replace(/4\./g,"04.");
            y = y.replace(/5\./g,"05.");
            y = y.replace(/6\./g,"06.");
            y = y.replace(/7\./g,"07.");
            y = y.replace(/8\./g,"08.");
            y = y.replace(/9\./g,"09.");

            if (direction === 'desc') {
                return (x < y) ? 1 : ((y < x) ? -1 : 0)
            } else {
                return (x > y) ? 1 : ((y > x) ? -1 : 0)
            }
        })
    }
    else if (key == 'group') {
        if (direction === 'desc') {
            array.sort((a,b) => {
                // Sort by grade number from string
                let a_nr = parseInt(JSON.parse(a.details).group.replace(/[^0-9]/g,''));
                let b_nr = parseInt(JSON.parse(b.details).group.replace(/[^0-9]/g,''));
                if (!a_nr) a_nr = 999
                if (!b_nr) b_nr = 999
                return (a_nr < b_nr ? 1 : (b_nr < a_nr) ? -1 : 0)
            })
        } else {
            array.sort((a,b) => {
                let a_nr = parseInt(JSON.parse(a.details).group.replace(/[^0-9]/g,''));
                let b_nr = parseInt(JSON.parse(b.details).group.replace(/[^0-9]/g,''));
                if (!a_nr) a_nr = 999
                if (!b_nr) b_nr = 999
                return (a_nr > b_nr ? 1 : (b_nr > a_nr) ? -1 : 0)
            })
        }
    }
    else if (key == 'code') {
        if (direction === 'desc') {
            array.sort((a,b) => (a.code < b.code) ? 1 : ((b.code < a.code) ? -1 : 0))
        } else {
            array.sort((a,b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))
        }
    }
    else if (key == 'allocated') {
        array.sort((a,b) => {

            let x = a.allocated_at != null ? 1 : 0;
            let y = b.allocated_at != null ? 1 : 0;
            if (direction === 'desc') {
                return (x > y) ? 1 : ((y > x) ? -1 : 0)
            } else {
                return (x < y) ? 1 : ((y < x) ? -1 : 0)
            }
        })
    }

    return array
}

function ConsumerList({ allocations, closeConnection })
{
    const [open, setOpen] = useState(false);

    const [order, setOrder] = useState('group');
    const [orderBy, setOrderBy] = useState('desc');

    const [sortedItems, setSortedItems] = useState([...allocations])

    const { saveSettings } = useSettings();

    const [ clickNum, setClickNum ] = useState(0);

    //let sortedItems = allocations;


    useEffect(() => {
        let sortedByName = sortByKey(allocations, 'name', 'desc')
        setSortedItems(sortByKey(sortedByName, order, orderBy))
    }, [allocations])

    const handleOpenDialog = (e) => {
        if (clickNum > 1)
        {
            setOpen(true);
            setClickNum(0);
        }
        else
        {
            setClickNum(clickNum+1);
            setTimeout(clearClickNum, 10000);
        }
    };

    const clearClickNum = () => {
        if (clickNum > 0)
        {
            setClickNum(0);
        }
    };

    const handleCloseDialog = () => {        
        setOpen(false);
    };

    const handleSortChange = (_order) => {    
        if(_order === order)
        {
            let newOrderBy = orderBy === "asc" ? "desc" : "asc";
            setOrderBy(newOrderBy);
            let sortedByName = sortByKey(sortedItems, 'name', 'asc')
            setSortedItems(sortByKey(sortedByName, _order, newOrderBy))
        }
        else
        {
          setOrder(_order);
          setOrderBy("asc");
          let sortedByName = sortByKey(sortedItems, 'name', 'asc')
          setSortedItems(sortByKey(sortedByName, _order, "asc"))
        }

      };


      const handleLogout = (e) => {      
        saveSettings({
          terminalId: 0,
          pin: '',
          branch: '',
          branchId: ''
        });
        closeConnection()
      };

    return (
        <div>
            <Logo height="40" onClick={handleOpenDialog} />
            <Dialog open={open} onClose={handleCloseDialog} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nemendalisti</DialogTitle>
                <DialogContent>
                    <Box>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                    <TableSortLabel
                                        active={ order === 'name' }
                                        direction={ order === 'name' ? orderBy : 'asc' }
                                        onClick={() => handleSortChange('name')}
                                    >
                                    Nafn
                                    </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                    <TableSortLabel
                                        active={ order === 'group' }
                                        direction={ order === 'group' ? orderBy : 'asc' }
                                        onClick={() => handleSortChange('group')}
                                    >
                                    Bekkur
                                    </TableSortLabel>
                                    
                                    </TableCell>
                                    <TableCell>
                                    <TableSortLabel
                                        active={ order === 'code' }
                                        direction={ order === 'code' ? orderBy : 'asc' }
                                        onClick={() => handleSortChange('code')}
                                    >
                                    Skólanúmer
                                    </TableSortLabel>
                                    
                                    </TableCell>
                                    <TableCell>
                                    <TableSortLabel
                                        active={ order === 'allocated' }
                                        direction={ order === 'allocated' ? orderBy : 'asc' }
                                        onClick={() => handleSortChange('allocated')}
                                    >
                                    Úthlutað
                                    </TableSortLabel>
                                    
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedItems !== null && sortedItems !== undefined && sortedItems.map((item) => {

                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            {JSON.parse(item.details).consumer}
                                        </TableCell>
                                        <TableCell>
                                            {JSON.parse(item.details).group}
                                        </TableCell>
                                        <TableCell>
                                            {item.code}
                                        </TableCell>
                                        <TableCell>
                                        {/* {allocations &&
                                        <>
                                            {(allocations.filter(a => a.c === item.c && a.a).length > 0 ? 'Já' : 'Nei')}
                                        </>
                                        } */}
                                        {item.allocated_at == null ? 'Nei' : 'Já'}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </Box>

                    <Box mt={3}>
                        <Button color="secondary" onClick={handleLogout}>Útskrá</Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Loka
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


  ConsumerList.propTypes = {
    /* subscriptions: PropTypes.object, */
    allocations: PropTypes.array,
    fetchSubscriptions: PropTypes.func
  };
  
  export default ConsumerList;
  