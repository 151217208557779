import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import AllocateView from './views/allocate';
import AuthView from './views/auth';
import AutoLogin from './views/autologin';
import { SnackbarProvider } from 'notistack';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import useSettings from './hooks/useSettings';

Bugsnag.start({
  enabledReleaseStages: ['production'],
  apiKey: '9956a1425d6e71d86e2138c810f14d5f',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
  root: {
    
  }
}));

export default function App() {
  const classes = useStyles();

  const { settings } = useSettings();

  const parsed = queryString.parse(window.location.search);

  return (
    <ErrorBoundary>
    <SnackbarProvider maxSnack={1}>
      <Container className={classes.root} maxWidth="lg">
          {parsed.pin !== undefined &&
          <AutoLogin />
          }
          {(settings.terminalId === 0 && parsed.pin === undefined) &&
          <AuthView />
          }
          {settings.terminalId > 0 &&
          <AllocateView />
          }
      </Container>
    </SnackbarProvider>
    </ErrorBoundary>
  );
}
